import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  Link,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useColorMode,
  VStack,
} from '@chakra-ui/react'
import reattempt from 'reattempt'
import { useWallet } from '@solana/wallet-adapter-react'
import { Transaction } from '@solana/web3.js'
import { Select } from 'chakra-react-select'
import React, { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { HiOutlineTicket } from 'react-icons/hi'
import { useAsyncFn } from 'react-use'
import { WalletMultiButton } from '../../../components/wallet-ui'
import { connection } from '../../../config/config'
import {
  handleTransactionError,
  handleTransaction,
} from '../../../utils/solUtils'
import { trpc } from '../../../utils/trpc'
import { raffleProgram } from '../../techRaffles/raffleConfig'
import { useSocialLink } from '../../admin/components/discord-link-ui/discordLinkHooks'
import { DiscordLinkMultiButton } from '../../admin/components/discord-link-ui/DiscordLinkMultiButton'
import {
  UseOnChainRaffleType,
  UseOnChainUserRaffleType,
} from '../../techRaffles/hooks/hookTypes'
import {
  OnChainRaffleType,
  OnChainUserRaffleType,
  purchaseTokenType,
  raffleMinType,
  raffleType,
} from '../../techRaffles/types'
import { CharkaSelectMinStyle } from '../consts/styles'
import { monetFeatureConfig } from '../../../config/monetFeatureConfig'
import { formatFloatForDisplay } from '../../../utils/utils'
import {
  useOnChainRaffle,
  useOnChainRaffleUserData,
} from '../../techRaffles/hooks/raffle'
import { TwitterLinkMultiButton } from '../../admin/components/twitter-link-ui/TwitterLinkMultiButton'
import { compareTokensForSort } from '../../../utils/tokenSortUtil'
import { RaffleStats } from './RaffleStats'

type BuyTicketsOnOverviewProps = {
  onChainRaffleRes: OnChainRaffleType | null
  onChainUserData: OnChainUserRaffleType | null
  raffle: raffleMinType & {
    onChainData?: OnChainRaffleType | null
    onChainUserData?: OnChainUserRaffleType | null
  }
  totalTicketsSold: number
  refetchOnChainData: () => Promise<any>
  variant: 'card' | 'table'
}

export const ConnectDiscordBoxOnOverview = (props: { text: string }) => {
  return (
    <VStack align='center' justify='center' gap='.4rem'>
      <Text fontWeight={600} fontSize='0.9rem'>
        {props.text}
      </Text>
      <Stack direction={{ base: 'column', md: 'row' }} gap={3}>
        <DiscordLinkMultiButton />
      </Stack>
    </VStack>
  )
}

export const HolderCheckBoxOnOverview = (props: {
  communityName: string
  discordInviteLink: string | null
  isLoading: boolean
}) => {
  if (props.isLoading) {
    return (
      <VStack align='center' justify='center' gap='.4rem'>
        <Text fontWeight={600} fontSize='0.9rem'>
          Holder check in progress! This can take up to 60 seconds.
        </Text>
        <Spinner />
      </VStack>
    )
  }
  return (
    <VStack align='center' justify='center' gap='.4rem'>
      <Text fontWeight={600} fontSize='0.9rem'>
        You aren't a holder of {props.communityName}.
      </Text>
      <Text fontSize='0.9rem'>
        Please check in the{' '}
        {props.discordInviteLink ? (
          <Link isExternal href={props.discordInviteLink}>
            {props.communityName} Discord
          </Link>
        ) : (
          <Text as='span'>{props.communityName} Discord</Text>
        )}{' '}
        if you have any holder roles.
      </Text>
      <Stack direction={{ base: 'column', md: 'row' }} gap={3}>
        <DiscordLinkMultiButton />
      </Stack>
    </VStack>
  )
}

const ticketPercentageSuggestions = [5, 10, 20]

export const BuyTicketsOnOverview: React.FC<BuyTicketsOnOverviewProps> = ({
  raffle,
  onChainRaffleRes,
  onChainUserData,
  totalTicketsSold,
  refetchOnChainData,
  variant,
}) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  const wallet = useWallet()
  const [buttonLoadingText, setButtonLoadingText] = useState('Confirming...')
  const [selectedPurchaseToken, setSelectedPurchaseToken] = useState<
    purchaseTokenType | undefined
  >(undefined)
  const [ticketAmountToBuy, setTicketAmountToBuy] = useState(4)
  const { discordUsername, twitterUsername } = useSocialLink()
  const { data: verifiedHolder, isLoading: isVerifiedHolderLoading } =
    trpc.useQuery(
      [
        'project.is-holder-in-project',
        {
          id: raffle.creatorProject?.id ?? '',
          wallet: wallet.publicKey?.toBase58(),
        },
      ],
      {
        enabled:
          !!raffle.creatorProject && raffle.holdersOnly && !!discordUsername,
      }
    )

  const allowedPurchaseTokens = useMemo(() => {
    if (!raffle.allowedPurchaseTokens) return []
    return raffle.allowedPurchaseTokens.sort((a, b) =>
      compareTokensForSort(a.token, b.token)
    )
  }, [raffle.allowedPurchaseTokens])

  useEffect(() => {
    if (
      selectedPurchaseToken &&
      raffle?.allowedPurchaseTokens.some(
        (purchaseToken) =>
          purchaseToken.token.symbol === selectedPurchaseToken.token.symbol
      )
    ) {
      return
    }

    if (raffle?.allowedPurchaseTokens?.length) {
      const selectedToken = raffle.allowedPurchaseTokens.find(
        (purchaseTokenObj) =>
          purchaseTokenObj.token.symbol === raffle.ticketPriceToken.symbol
      )
      const solToken = raffle.allowedPurchaseTokens.find(
        (purchaseTokenObj) => purchaseTokenObj.token.symbol === 'SOL'
      )
      if (selectedToken) {
        setSelectedPurchaseToken(selectedToken)
      } else if (solToken) {
        setSelectedPurchaseToken(solToken)
      } else {
        setSelectedPurchaseToken(raffle.allowedPurchaseTokens[0])
      }
    }
  }, [raffle])

  const previewTicketPrice = useMemo(() => {
    if (!raffle || !selectedPurchaseToken) return null
    const ticketPriceToken = raffle.ticketPriceToken
    if (selectedPurchaseToken.token.symbol === ticketPriceToken.symbol) {
      return raffle.ticketPrice
    }
    if (selectedPurchaseToken.fixedPrice) {
      return selectedPurchaseToken.fixedPrice
    }
    const previewTicketPrice =
      (raffle.ticketPrice * (ticketPriceToken.lastUsdcPrice ?? 0)) /
      (selectedPurchaseToken.token.lastUsdcPrice ?? 1)
    const discount = selectedPurchaseToken.discount ?? 0
    console.log({ discount })
    console.log({ previewTicketPrice })

    const discountedPrice = previewTicketPrice * (1 - discount / 100)
    return discountedPrice * 1.0125 // 1.0125 for slippage
  }, [raffle, selectedPurchaseToken])

  const buyRaffleTicketMut = trpc.useMutation(['raffle.buyRaffleTicket'], {
    onSuccess: async (data) => {},
    onError: (error) => {
      console.log('error buy ticket request', error)
      toast.error(error.message)
    },
  })

  const confirmBuyTicketMut = trpc.useMutation(
    ['raffle.confirmBuyRaffleTicket'],
    {
      onSuccess: async (data) => {
        toast.success(`Earned ${data?.gemsEarned} gems`)
      },
      onError: (error) => {
        console.log('error buy ticket confirm for adding points', error)
      },
    }
  )

  const [buyTicketRes, buyTicket] = useAsyncFn(async () => {
    if (
      !wallet.publicKey ||
      !wallet.signTransaction ||
      !raffle ||
      !selectedPurchaseToken
    )
      return

    if (!monetFeatureConfig.ticketSale) {
      toast('Ticket sale disabled')
      return
    }

    console.log('buying ticket starting')

    try {
      setButtonLoadingText('Creating transaction...')
      const buyTicketResData = await buyRaffleTicketMut.mutateAsync({
        raffleId: raffle.id,
        payTokenSymbol: selectedPurchaseToken.token.symbol,
        ticketCount: ticketAmountToBuy,
        userWalletBase58: wallet.publicKey.toBase58(),
      })

      console.log('successful buy ticket init', buyTicketResData)
      if (
        !wallet.publicKey ||
        !wallet.signTransaction ||
        !raffle ||
        !selectedPurchaseToken
      )
        return

      const transaction = Transaction.from(
        Buffer.from((buyTicketResData as any).trans)
      )

      setButtonLoadingText('Approve in wallet...')
      const signedTx = await wallet.signTransaction(transaction)
      setButtonLoadingText('Sending on-chain...')

      const serial = signedTx.serialize({
        verifySignatures: false,
        requireAllSignatures: false,
      })

      const tx = await reattempt.run(
        { times: 6, delay: 600 },
        async () => {
          const [tx1, tx2, tx3] = await Promise.all([
            connection.sendRawTransaction(serial, {skipPreflight: true}),
            connection.sendRawTransaction(serial, {skipPreflight: true}),
            connection.sendRawTransaction(serial, {skipPreflight: true}),
            connection.sendRawTransaction(serial, {skipPreflight: true}),
            connection.sendRawTransaction(serial, {skipPreflight: true})
          ])

          return tx1
        }
      )


      setButtonLoadingText('Confirming on-chain...')

      console.log('handle tx')

      await handleTransaction(tx, {
        blockhash: buyTicketResData.blockhash,
        commitment: 'processed',
        showLogs: true,
      })

      setButtonLoadingText('Fetching new data...')

      await Promise.all([
        refetchOnChainData(),
        confirmBuyTicketMut.mutateAsync({
          raffleId: raffle.id,
          userWalletBase58: wallet.publicKey.toBase58(),
          tokenId: selectedPurchaseToken.token.id,
          tokenSymbol: selectedPurchaseToken.token.symbol,
          txSignature: tx,
        }),
      ])

      toast(
        `Bought ${ticketAmountToBuy} ticket${ticketAmountToBuy > 1 ? 's' : ''}`,
        {
          duration: 5000,
          icon: '🎉',
          style: {
            fontSize: '1.2rem',
            fontWeight: 600,
          },
        }
      )
    } catch (e: any) {
      console.error('Error in buying tickets', e)
      toast.error('Buying ticket failed')
      const anchorErrorMessage = handleTransactionError(e)
      if (anchorErrorMessage) {
        toast.error(anchorErrorMessage)
      } else if (e.message.includes('0x12d')) {
        toast.error('Raffle has ended')
      } else {
        toast.error(e.message)
      }
    }
  }, [wallet, selectedPurchaseToken, ticketAmountToBuy, raffle.id])

  if (!wallet.publicKey) return <></>

  const userTicketCount = onChainUserData?.counter ?? 0

  return (
    <Box padding={variant === 'card' ? '.7rem' : '0'}>
      {totalTicketsSold === raffle.maxTickets ? (
        <></>
      ) : (
        <>
          <Stack
            mt='.2rem'
            justify='space-between'
            align='center'
            gap='1rem'
            direction={['column', 'column', 'row']}
            w='100%'
          >
            <HStack alignItems='flex-end' justifyContent='center' w='100%'>
              <VStack
                w='100%'
                maxW={
                  variant === 'table'
                    ? ['120px', '120px', '120px', '120px', '120px', '140px']
                    : 'unset'
                }
              >
                {raffle.maxTickets && (
                  <Flex justifyContent={'flex-start'} w='100%' gap='6px'>
                    {ticketPercentageSuggestions.map((percentage) => {
                      return (
                        <Button
                          size='xs'
                          fontSize='.75rem'
                          backgroundColor={'rgba(0, 0, 0, 0.05)'}
                          border={
                            isDarkMode
                              ? '1px solid #787878'
                              : '1px solid #E9E9E9'
                          }
                          color={isDarkMode ? 'hsla(0,0%,100%,.35)' : 'black'}
                          borderRadius='full'
                          onClick={() => {
                            const maxTickets = raffle.maxTickets!
                            const ticketsUnrounded =
                              (percentage / 100) * maxTickets
                            const ticketsRounded =
                              percentage === 20
                                ? Math.floor(ticketsUnrounded)
                                : Math.round(ticketsUnrounded)
                            setTicketAmountToBuy(ticketsRounded)
                          }}
                        >
                          {percentage}%
                        </Button>
                      )
                    })}
                  </Flex>
                )}
                <Flex
                  alignItems={'center'}
                  background={
                    isDarkMode ? 'rgba(255, 255, 255, 0.06)' : '#fefefe'
                  }
                  h={variant === 'card' ? '2.81rem' : '2.61rem'}
                  color={isDarkMode ? '#fff' : '#232323'}
                  rounded='full'
                  border='solid 1px'
                  borderColor={isDarkMode ? '#494949' : '#E9E9E9'}
                  w='100%'
                >
                  <Button
                    bg='transparent'
                    color={isDarkMode ? 'white' : 'black'}
                    ml='1'
                    onClick={() => {
                      setTicketAmountToBuy(
                        ticketAmountToBuy > 1 ? ticketAmountToBuy - 1 : 1
                      )
                    }}
                  >
                    -
                  </Button>
                  <Input
                    border='none'
                    color={isDarkMode ? '#fff' : '#232323'}
                    background={'transparent'}
                    rounded='full'
                    type='number'
                    min={1}
                    max={
                      raffle.maxTicketsPerUser
                        ? raffle.maxTicketsPerUser
                        : undefined
                    }
                    w='100%'
                    placeholder='No of Tickets'
                    textAlign='center'
                    padding={0}
                    minW='30px'
                    value={ticketAmountToBuy}
                    onChange={(e) => {
                      setTicketAmountToBuy(Number(e.target.value))
                    }}
                  ></Input>
                  <Button
                    mr='1'
                    bg='transparent'
                    color={isDarkMode ? 'white' : 'black'}
                    onClick={() => {
                      setTicketAmountToBuy(ticketAmountToBuy + 1)
                    }}
                  >
                    +
                  </Button>
                </Flex>
              </VStack>
              <VStack
                w='100%'
                maxW={
                  variant === 'table'
                    ? ['110px', '110px', '110px', '110px', '110px', '130px']
                    : 'unset'
                }
              >
                <Text textAlign='right' w='100%' color='hsla(0,0%,100%,.35)'>
                  Pay in
                </Text>
                <Select
                  className='app-charka-react-select'
                  chakraStyles={CharkaSelectMinStyle(
                    colorMode,
                    variant === 'card'
                      ? '2.8rem !important'
                      : '2.4rem !important'
                  )}
                  onChange={(newVal: any, actionMeta) => {
                    console.log('selected', { newVal })

                    const purchaseToken = raffle.allowedPurchaseTokens.find(
                      (purchaseTokenObj) =>
                        purchaseTokenObj.token.symbol === newVal?.value
                    )
                    if (!purchaseToken) return
                    setSelectedPurchaseToken(purchaseToken)
                  }}
                  value={{
                    label: selectedPurchaseToken?.token.symbol,
                    value: selectedPurchaseToken?.token.symbol,
                  }}
                  options={allowedPurchaseTokens.map((purchaseTokenObj) => ({
                    label: purchaseTokenObj.token.symbol,
                    value: purchaseTokenObj.token.symbol,
                  }))}
                />
              </VStack>
            </HStack>
          </Stack>

          {!!wallet.publicKey &&
            ((raffle.type === 'WHITELIST' || raffle.holdersOnly) &&
            !discordUsername ? (
              <>
                {raffle.type === 'WHITELIST' && (
                  <ConnectDiscordBoxOnOverview text='Please connect your Discord Account for whitelist raffles. This allows the raffle creator to submit your spots.' />
                )}
                {raffle.type !== 'WHITELIST' && raffle.holdersOnly && (
                  <ConnectDiscordBoxOnOverview text='Please connect your Discord Account for holder-only raffles. This allows us to check your holder status.' />
                )}
              </>
            ) : raffle.holdersOnly && discordUsername && !verifiedHolder ? (
              <HolderCheckBoxOnOverview
                communityName={raffle.creatorProject!.communityName}
                discordInviteLink={raffle.creatorProject!.discordInviteLink}
                isLoading={isVerifiedHolderLoading}
              />
            ) : raffle.needsSocial && !twitterUsername ? (
              <Stack direction={{ base: 'column', md: 'row' }} gap={3}>
                <TwitterLinkMultiButton />
              </Stack>
            ) : (
              <Stack>
                <Flex alignItems={'center'} justifyContent='center'>
                  <Button
                    variant={isDarkMode ? 'primaryDark' : 'primary'}
                    loadingText={buttonLoadingText}
                    isLoading={buyTicketRes.loading}
                    w='full'
                    maxW={variant === 'card' ? 'unset' : '260px'}
                    mt='.8rem'
                    height={
                      variant === 'card' ? ['3.4rem', '3.8rem'] : '2.8rem'
                    }
                    fontSize={['1rem', '1rem']}
                    onClick={() => {
                      buyTicket()
                    }}
                  >
                    Buy {ticketAmountToBuy} ticket
                    {ticketAmountToBuy === 1 ? '' : 's'}
                    {previewTicketPrice ? (
                      <>
                        {' '}
                        for{' '}
                        {formatFloatForDisplay(
                          previewTicketPrice * ticketAmountToBuy
                        )}{' '}
                        {selectedPurchaseToken?.token.symbol}
                      </>
                    ) : (
                      ''
                    )}
                  </Button>
                </Flex>

                {/*onChainRaffleRes && variant === 'card' && (
                  <Flex justifyContent={'center'} alignItems={'center'}>
                    <Text maxWidth='80%' textAlign={'center'} fontSize='0.7rem'>
                      {raffle.maxTickets &&
                      onChainRaffleRes.ticketCount >= raffle.maxTickets ? (
                        <></>
                      ) : (
                        <>
                          Buying {ticketAmountToBuy}{' '}
                          {userTicketCount > 0 ? 'more' : ''} ticket
                          {ticketAmountToBuy === 1 ? '' : 's'} currently gives{' '}
                          {userTicketCount > 0 ? 'you' : ''} a{' '}
                          {formatFloatForDisplay(
                            ((ticketAmountToBuy + userTicketCount) /
                              (totalTicketsSold + ticketAmountToBuy)) *
                              100
                          )}
                          % {userTicketCount > 0 ? 'total' : ''} chance of
                          winning this raffle
                        </>
                      )}
                    </Text>
                  </Flex>
                          )*/}
              </Stack>
            ))}
        </>
      )}

      {variant === 'card' && (
        <RaffleStats
          raffle={raffle}
          onChainUserData={onChainUserData}
        ></RaffleStats>
      )}
    </Box>
  )
}
