import {
  Box,
  Flex, Image,
  Spinner,
  Text,
  useColorMode,
  VStack
} from '@chakra-ui/react'
import { useWallet } from '@solana/wallet-adapter-react'
import { useMemo } from 'react'
import { formatFloatForDisplay } from '../../../utils/utils'
import { useUser } from '../../common/auth/authHooks'
import { useParticipantDataForSingleRaffleId } from '../../rewards/hooks/useParticipant'
import {
  getPointsForBuyingTicketsWithMultiplier,
  PointsGoLiveDate
} from '../../rewards/utils/pointsUtils'
import { useOnChainRaffleUserData } from '../../techRaffles/hooks/raffle'
import { OnChainRaffleType, OnChainUserRaffleType, raffleMinType } from '../../techRaffles/types'

type RaffleStatsProps = {
  onChainUserData: OnChainUserRaffleType | null
  onChainUserDataIsLoading?: boolean
  raffle: raffleMinType & { onChainData?: OnChainRaffleType | null, onChainUserData?: OnChainUserRaffleType | null }
  hasEnded?: boolean
  isLoading?: boolean
}

export const RaffleStats: React.FC<RaffleStatsProps> = ({
  raffle,
  onChainUserData,
  onChainUserDataIsLoading,
  hasEnded,
}) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  const wallet = useWallet()
  const { data: user, isLoading } = useUser()

  const isRaffleFinalized = useMemo(() => {
    return raffle.status === 'FINISHED' || raffle.status === 'CANCELLED'
  }, [raffle.status])
  
  const participantData = useParticipantDataForSingleRaffleId({
    userId: user?.id,
    raffleId: raffle.id,
  })

  const userTicketCountNullish = useMemo(() => {
    return isRaffleFinalized ? participantData.data?.ticketsBought : onChainUserData?.counter
  }, [isRaffleFinalized, participantData.data?.ticketsBought, onChainUserData?.counter])

  const volumeInSol =
    (raffle.estimateTicketPriceInSol ?? 0) *
    (userTicketCountNullish ?? 0)
  
  const pointsEarned = getPointsForBuyingTicketsWithMultiplier(
    volumeInSol,
    raffle.pointsMultiplier
  )

  return (
    <>
      {/*onChainRaffleRes && (
        <>
          {(raffle.maxTickets &&
            onChainRaffleRes.ticketCount >= raffle.maxTickets) ||
          hasEnded ? (
            <Flex justifyContent={'center'} alignItems={'center'}>
              <Text maxWidth='80%' textAlign={'center'} fontSize='0.825rem'>
                Chance of winning:{' '}
                {formatFloatForDisplay(
                  ((userTicketCountNullish ?? 0) /
                    onChainRaffleRes.ticketCount) *
                    100
                )}
                %
              </Text>
            </Flex>
          ) : (
            <></>
          )}
        </>
          )*/}

      {(userTicketCountNullish ?? 0) > 0 && (
        <VStack gap='.4rem' mt='1rem'>
          <Text
            textAlign={'center'}
            fontSize={['0.9rem', '0.9rem']}
            fontWeight={600}
            color={isDarkMode ? '#dbd8d8' : '#6b6b6b'}
          >
            👏 You purchased{' '}
            {onChainUserDataIsLoading ? (
              <Spinner size='sm' mt='0.3rem' />
            ) : (
              userTicketCountNullish ?? (
                <Spinner size='sm' mt='0.3rem' />
              )
            )}{' '}
            ticket{userTicketCountNullish === 1 ? '' : 's'}{' '}
          </Text>
          {(raffle.ends > PointsGoLiveDate && participantData.data?.gemsEarned) ? (
            <Box
              color='#FFC728'
              bg={isDarkMode ? 'primaryDark' : '#FFF8E4'}
              h='40px'
              maxW='220px'
              w='200px'
              border={isDarkMode ? 'solid 1px #FFffff50' : 'solid 1px #FFDBB9'}
              borderRadius='full'
              display='flex'
              justifyContent='center'
              alignItems='center'
              fontWeight='bold'
              gap='2'
            >
              <Image src='/images/rewards/GemsShine.svg' />
              <Text
                ml={0}
                fontWeight={600}
                fontSize='.9rem'
                color={isDarkMode ? 'white' : '#FF8F28'}
              >
                {participantData.isLoading ? (<Spinner size='sm'></Spinner>) : formatFloatForDisplay(participantData.data?.gemsEarned ?? pointsEarned.gems)}{' '}
                GEMS
                <Text
                  as='span'
                  fontWeight={600}
                  ml={0}
                  fontSize='.9rem'
                  display={['none', 'none', 'unset']}
                  color={isDarkMode ? 'white' : '#FF8F28'}
                >
                  {' '}earned
                </Text>
              </Text>
            </Box>
          ) : (
            <></>
          )}
        </VStack>
      )}
    </>
  )
}
